import React, { useState } from 'react';
import Navbar from '../StrongComponents/Navbar';
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { Button, Grid, Snackbar } from "@mui/material";


const ContactPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const saveData = () => {
        console.log(JSON.stringify({
            name: name,
            email: email,
            phone: phone,
            message: message,
        }));

        // Perform validation for email and phone number
        const phoneRegex = /^[0-9]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!phone.match(phoneRegex)) {
            alert("Please enter a valid phone number.");
            return;
        }

        if (!email.match(emailRegex)) {
            alert("Please enter a valid email address.");
            return;
        }

        // Data sent successfully, clear form and show notification
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setOpenSnackbar(true);
        
        // Fetch request to send data to Google Sheets
        fetch(
            "https://script.google.com/macros/s/AKfycbyiSTJtkS78aUUHuuez5ThIx2Ml_cmh14hsv_dwA94QGP-x4UsjOzdXwlgnkyGiOc5Y/exec",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "no-cors",
                body: JSON.stringify({
                    name: name,
                    email: email,
                    phone: phone,
                    message: message,
                }),
            }
        )
        .then((response) => response.text())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));
    };

    return ( 
        <div className="bg-gray-100 text-black dark:text-white">
            {/* Navigation bar */}
            <Navbar />

            {/* Contact Form Section */}
            <section className="py-20 dark:bg-gray-300 ">
                <div className="container mx-auto  ">
                    <h1 className="text-3xl sm:text-4xl font-bold mb-8 dark:text-black text-center">Contact Us</h1>
                    <Grid container spacing={3} className='p-5  '>
                        <Grid item xs={12} sm={6} className=''>
                            <TextField className=' '
                                value={name}
                                fullWidth
                                label="Name"
                                variant="filled" 
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                value={email}
                                fullWidth
                                label="Email"
                                variant="filled"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={phone}
                                fullWidth
                                label="Phone"
                                variant="filled"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={message}
                                fullWidth
                                label="Message"
                                multiline
                                minRows={5}
                                variant="filled"
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                endIcon={<SendIcon />}
                                onClick={saveData}
                            >
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </section>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                message="Message sent successfully!"
            />




           




            {/* Footer Section */}
            <footer className="bg-gray-800 text-white py-8">
                <div className="container mx-auto px-4 text-center">
                    <p className="text-gray-400">&copy; 2024 Sticker & Canvas. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default ContactPage;
