import React, { useState, useEffect } from 'react';
import Navbar from '../StrongComponents/Navbar';

const Test = () => {
  const questions = [
    { id: 1, question: 'What is the capital of France?', options: ['London', 'Paris', 'Berlin', 'Madrid'], correctAnswer: 'Paris' },
    { id: 2, question: 'Who wrote Hamlet?', options: ['Shakespeare', 'Chaucer', 'Milton', 'Dickens'], correctAnswer: 'Shakespeare' },
    { id: 3, question: 'What is the largest planet in our solar system?', options: ['Earth', 'Mars', 'Jupiter', 'Saturn'], correctAnswer: 'Jupiter' },
    { id: 4, question: 'What is the chemical symbol for water?', options: ['H2O', 'O2', 'CO2', 'H2'], correctAnswer: 'H2O' },
    { id: 5, question: 'Who painted the Mona Lisa?', options: ['Van Gogh', 'Da Vinci', 'Picasso', 'Monet'], correctAnswer: 'Da Vinci' },
    { id: 6, question: 'What is the speed of light?', options: ['300,000 km/s', '150,000 km/s', '450,000 km/s', '600,000 km/s'], correctAnswer: '300,000 km/s' },
    { id: 7, question: 'What is the powerhouse of the cell?', options: ['Nucleus', 'Mitochondria', 'Ribosome', 'Chloroplast'], correctAnswer: 'Mitochondria' },
    { id: 8, question: 'What is the capital of Japan?', options: ['Seoul', 'Tokyo', 'Beijing', 'Bangkok'], correctAnswer: 'Tokyo' },
    { id: 9, question: 'Who discovered penicillin?', options: ['Marie Curie', 'Alexander Fleming', 'Albert Einstein', 'Isaac Newton'], correctAnswer: 'Alexander Fleming' },
    { id: 10, question: 'What is the smallest prime number?', options: ['0', '1', '2', '3'], correctAnswer: '2' },
  ];

  const [studentDetails, setStudentDetails] = useState({ name: '', class: '', email: '', preparingForNeet: '' });
  const [currentSet, setCurrentSet] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [students, setStudents] = useState(() => {
    // Load participants from localStorage
    const savedStudents = localStorage.getItem('students');
    return savedStudents ? JSON.parse(savedStudents) : [];
  });
  const [score, setScore] = useState(0);

  const questionsPerSet = 10;
  const totalSets = Math.ceil(questions.length / questionsPerSet);

  const handleAnswerSelect = (questionId, answer) => {
    setSelectedAnswers({ ...selectedAnswers, [questionId]: answer });
  };

  const handleSubmit = () => {
    let score = 0;
    questions.forEach((question) => {
      if (selectedAnswers[question.id] === question.correctAnswer) {
        score++;
      }
    });
    setScore(score);
    const newStudent = { ...studentDetails, score };
    const updatedStudents = [...students, newStudent];
    setStudents(updatedStudents);
    localStorage.setItem('students', JSON.stringify(updatedStudents));
    setShowResults(true);
  };

  const handleNextSet = () => {
    if (currentSet < totalSets - 1) {
      setCurrentSet(currentSet + 1);
    }
  };

  const handlePreviousSet = () => {
    if (currentSet > 0) {
      setCurrentSet(currentSet - 1);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentDetails({ ...studentDetails, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const currentQuestions = questions.slice(currentSet * questionsPerSet, (currentSet + 1) * questionsPerSet);

  const sortedStudents = students.sort((a, b) => b.score - a.score).slice(0, 7);

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="p-5">
        <h1 className="text-2xl font-bold text-center">
          Would you like to test Your skills?
        </h1>
      </div>
      {!submitted ? (
        <div className="max-w-2xl mx-auto p-5 bg-white shadow-md rounded-lg">
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={studentDetails.name}
                onChange={handleInputChange}
                className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Class</label>
              <select
                name="class"
                value={studentDetails.class}
                onChange={handleInputChange}
                className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              >
                <option value="">Select Class</option>
                <option value="10th">10th</option>
                <option value="11th">11th</option>
                <option value="12th">12th</option>
                <option value="dropout">Dropout</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={studentDetails.email}
                onChange={handleInputChange}
                className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Are you preparing for NEET?</label>
              <div className="mt-2">
                <label className="inline-flex items-center mr-6">
                  <input
                    type="radio"
                    name="preparingForNeet"
                    value="yes"
                    checked={studentDetails.preparingForNeet === 'yes'}
                    onChange={handleInputChange}
                    className="form-radio"
                    required
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="preparingForNeet"
                    value="no"
                    checked={studentDetails.preparingForNeet === 'no'}
                    onChange={handleInputChange}
                    className="form-radio"
                    required
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                Start Test
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="max-w-2xl mx-auto p-5 bg-white shadow-md rounded-lg">
          {!showResults ? (
            <div>
              <h3 className="text-xl font-semibold mb-4">Question Set {currentSet + 1}:</h3>
              {currentQuestions.map((question, index) => (
                <div key={question.id} className="mb-6">
                  <p className="font-bold">{question.id}. {question.question}</p>
                  <ul className="list-none pl-0">
                    {question.options.map((option, index) => (
                      <li key={index} className="my-2">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name={`question${question.id}`}
                            value={option}
                            checked={selectedAnswers[question.id] === option}
                            onChange={() => handleAnswerSelect(question.id, option)}
                            className="mr-2"
                          />
                          {option}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <div className="flex justify-between mt-4">
                {currentSet > 0 && <button onClick={handlePreviousSet} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Previous Set</button>}
                {currentSet < totalSets - 1 && <button onClick={handleNextSet} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Next Set</button>}
                {currentSet === totalSets - 1 && <button onClick={handleSubmit} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">Submit</button>}
              </div>
            </div>
          ) : (
            <div>
              <h2 className="text-xl font-semibold mb-4">Results:</h2>
              <ul className="list-none pl-0">
                {questions.map((question, index) => (
                  <li key={index} className="mb-6">
                    <p className="font-bold">{question.question}</p>
                    <p>Correct Answer: <span className="text-green-500">{question.correctAnswer}</span></p>
                    <p>Your Answer: <span className={selectedAnswers[question.id] === question.correctAnswer ? "text-green-500" : "text-red-500"}>{selectedAnswers[question.id]}</span></p>
                  </li>
                ))}
              </ul>
              <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Top 7 Students:</h2>
                <ul className="list-none pl-0">
                  {sortedStudents.map((student, index) => (
                    <li key={index} className="mb-2">{index + 1}. {student.name} - {student.score}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Test;
