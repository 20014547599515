import React, { useState } from 'react'

const ToggleThemeContext = React.createContext(null)
const ToggleThemeProvider = ({children}) => {
    const [darkTheme,setDarkTheme] = useState(false)
  return (
    <ToggleThemeContext.Provider
     value={{darkTheme,setDarkTheme}}
    >
        <div className={darkTheme?"dark":""}>
            {children}
        </div>
    </ToggleThemeContext.Provider>
  )
}

export default ToggleThemeProvider
export const useToggleTheme =()=> React.useContext(ToggleThemeContext);