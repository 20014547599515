import React from 'react'
import Navbar from '../StrongComponents/Navbar'
import Roadmap from '../StrongComponents/Roadmap'

export default function PracticeTestsPage() {
  return (
      <div>
        <Navbar/>
    <div>
     <Roadmap/>
    </div>
      </div>
  )
}
