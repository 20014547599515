import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './StrongScreens/HomePage';
// import StickersPage from './StrongScreens/StickersPage';
// import CanvasPage from './StrongScreens/CanvasPage';
import ContactPage from './StrongScreens/ContactPage';
// import AboutPage from './StrongScreens/AboutPage'; // Import the AboutPage component
// import NotFoundPage from './StrongScreens/NotFoundPage'; // Assuming this is your 404 page
import ToggleThemeProvider from './StrongComponents/ToggleThemeProvider';
import CoursesPage from '../src/StrongScreens/CoursesPage';
import PracticeTestsPage from '../src/StrongScreens/PracticeTestsPage';
import SyllabusPage from '../src/StrongScreens/SyllabusPage';
import BlogPage from '../src/StrongScreens/BlogPage';
import NEETExamOverviewPage from '../src/RoadMap/NEETExamOverviewPage';
import StudyTechniques from '../src/RoadMap/StudyTechniquesPage';
import PhysicsFundamentals  from '../src/RoadMap/PhysicsFundamentalsPage';
import ChemistryFundamentals from '../src/RoadMap/ChemistryFundamentalsPage';
import BiologyFundamentals from '../src/RoadMap/BiologyFundamentalsPage';
import PracticeExercises  from '../src/RoadMap/PracticeExercisesPage';
import MockTests from '../src/RoadMap/MockTestsPage';
import RevisionDoubtClearing from '../src/RoadMap/RevisionDoubtClearingPage';
import TestTakingStrategies from '../src/RoadMap/TestTakingStrategiesPage';
import Support from '../src/RoadMap/FeedbackSupportPage';

const App = () => {
    return (
        <ToggleThemeProvider>

<Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/courses" element={<CoursesPage />} />
                <Route path="/practice-tests" element={<PracticeTestsPage />} />
                <Route path="/syllabus" element={<SyllabusPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/introduction-to-neet-exam" element={<NEETExamOverviewPage />} />
                <Route path="/study-techniques" element={<StudyTechniques />} />
                <Route path="/physics-fundamentals" element={<PhysicsFundamentals />} />
                <Route path="/chemistry-fundamentals" element={<ChemistryFundamentals />} />
                <Route path="/biology-fundamentals" element={<BiologyFundamentals />} />
                <Route path="/practice-problem-sets" element={<PracticeExercises />} />
                <Route path="/mock-tests-sample-papers" element={<MockTests />} />
                <Route path="/revision-doubt-clearing" element={<RevisionDoubtClearing />} />
                <Route path="/test-taking-strategies" element={<TestTakingStrategies />} />
                <Route path="/feedback-support" element={<Support />} />

            </Routes>


        </ToggleThemeProvider>
    );
};

export default App;
