import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid, Card, CardContent, Typography, Link, useMediaQuery, useTheme, Box } from "@mui/material";
import BookCover10th from "../StrongImages/NCRTBooks/10th/Science10th.jpg";
import BookCover11Bio1 from "../StrongImages/NCRTBooks/11th/Biology/Bio11th.jpg";
import BookCover11Chem1 from "../StrongImages/NCRTBooks/11th/Chemistry/Chemestry11thPart1.jpg";
import BookCover11Chem2 from "../StrongImages/NCRTBooks/11th/Chemistry/Chemestry11thPart2.jpg";
import BookCover11Phys1 from "../StrongImages/NCRTBooks/11th/Physics/Physics11thPart1.jpg";
import BookCover11Phys2 from "../StrongImages/NCRTBooks/11th/Physics/Physics11thPart2.jpg";
import BookCover12Bio1 from "../StrongImages/NCRTBooks/12th/Biology/Bio.jpg";
import BookCover12Chem1 from "../StrongImages/NCRTBooks/12th/Chemistry/Chemistry12thPart1.jpg";
import BookCover12Chem2 from "../StrongImages/NCRTBooks/12th/Chemistry/Chemistry12thPart2.jpg";
import BookCover12Phys1 from "../StrongImages/NCRTBooks/12th/Physics/Physics12thPart1.jpg";
import BookCover12Phys2 from "../StrongImages/NCRTBooks/12th/Physics/Physics12thPart2.jpg";

const books = [
  {
    title: "10th Grade Science",
    image: BookCover10th,
    pdfLink: "https://ncert.nic.in/textbook.php?jesc1=0-13",
    additionalLinks: [
      { url: "https://www.example.com/10th-science-chapter-summaries", text: "Chapter Summaries 10th Grade Science" },
      { url: "https://www.example.com/10th-science-important-questions", text: "Important Questions 10th Grade Science" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests 10th Grade Science" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures 10th Grade Science" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes 10th Grade Science" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests 10th Grade Science" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards 10th Grade Science" },
    ],
  },
  {
    title: "11th Grade Biology",
    image: BookCover11Bio1,
    pdfLink: "https://ncert.nic.in/textbook.php?kebo1=0-13",
    additionalLinks: [
      { url: "https://www.example.com/11th-biology-chapter-summaries", text: "Chapter Summaries 11th Grade Biology" },
      { url: "https://www.example.com/11th-biology-important-questions", text: "Important Questions 11th Grade Biology" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests 11th Grade Biology" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures 11th Grade Biology" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes 11th Grade Biology" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests 11th Grade Biology" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards 11th Grade Biology" },
    ],
  },
  {
    title: "11th Grade Chemistry Part 1",
    image: BookCover11Chem1,
    pdfLink: "https://ncert.nic.in/textbook.php?kech1=0-13",
    additionalLinks: [
      { url: "https://www.example.com/11th-chemistry-part1-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/11th-chemistry-part1-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
  {
    title: "11th Grade Chemistry Part 2",
    image: BookCover11Chem2,
    pdfLink: "https://ncert.nic.in/textbook.php?kech2=0-13",
    additionalLinks: [
      { url: "https://www.example.com/11th-chemistry-part2-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/11th-chemistry-part2-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
  {
    title: "11th Grade Physics Part 1",
    image: BookCover11Phys1,
    pdfLink: "https://ncert.nic.in/textbook.php?keph1=0-7",
    additionalLinks: [
      { url: "https://www.example.com/11th-physics-part1-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/11th-physics-part1-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
  {
    title: "11th Grade Physics Part 2",
    image: BookCover11Phys2,
    pdfLink: "https://ncert.nic.in/textbook.php?keph2=0-7",
    additionalLinks: [
      { url: "https://www.example.com/11th-physics-part2-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/11th-physics-part2-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
  {
    title: "12th Grade Biology",
    image: BookCover12Bio1,
    pdfLink: "https://ncert.nic.in/textbook.php?lebo1=0-13",
    additionalLinks: [
      { url: "https://www.example.com/12th-biology-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/12th-biology-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
  {
    title: "12th Grade Chemistry Part 1",
    image: BookCover12Chem1,
    pdfLink: "https://ncert.nic.in/textbook.php?lech1=0-13",
    additionalLinks: [
      { url: "https://www.example.com/12th-chemistry-part1-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/12th-chemistry-part1-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
  {
    title: "12th Grade Chemistry Part 2",
    image: BookCover12Chem2,
    pdfLink: "https://ncert.nic.in/textbook.php?lech2=0-13",
    additionalLinks: [
      { url: "https://www.example.com/12th-chemistry-part2-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/12th-chemistry-part2-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
  {
    title: "12th Grade Physics Part 1",
    image: BookCover12Phys1,
    pdfLink: "https://ncert.nic.in/textbook.php?leph1=0-7",
    additionalLinks: [
      { url: "https://www.example.com/12th-physics-part1-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/12th-physics-part1-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
  {
    title: "12th Grade Physics Part 2",
    image: BookCover12Phys2,
    pdfLink: "https://ncert.nic.in/textbook.php?leph2=0-7",
    additionalLinks: [
      { url: "https://www.example.com/12th-physics-part2-chapter-summaries", text: "Chapter Summaries" },
      { url: "https://www.example.com/12th-physics-part2-important-questions", text: "Important Questions" },
      { url: "https://www.example.com/10th-science-practice-tests", text: "Practice Tests" },
      { url: "https://www.example.com/10th-science-video-lectures", text: "Video Lectures" },
      { url: "https://www.example.com/10th-science-study-notes", text: "Study Notes" },
      { url: "https://www.example.com/10th-science-mock-tests", text: "Mock Tests" },
      { url: "https://www.example.com/10th-science-flashcards", text: "Flashcards" },
    ],
  },
];

function VerticalTabs() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabStyles = {
    fontSize: isMobile ? "0.75rem" : "1rem",
    minWidth: "auto",
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={3}>
        <Tabs
          orientation={isMobile ? "horizontal" : "vertical"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: "divider", width: "100%" }}
        >
          {books.map((book, index) => (
            <Tab key={index} label={book.title} sx={tabStyles} />
          ))}
        </Tabs>
      </Grid>
      <Grid className=" " item xs={12} sm={9}>
        {books.map((book, index) => (
          <TabPanel  key={index} value={value} index={index}>
            <Card className=" ">

              <CardContent className=" flex justify-between">
                <Grid container md={12} >
<Grid item md={6} xs={12} sm={12}>
              <div>
                <Typography variant="h5" gutterBottom>
                  {book.title}
                </Typography>
                <img src={book.image} alt={book.title} style={{ maxWidth: "100%", height: "auto" }} />
                <Typography variant="body1" gutterBottom>
                  <Link href={book.pdfLink} target="_blank" rel="noopener">
                    View PDF
                  </Link>
                </Typography>
                  </div>

</Grid>
<Grid item md={6} xs={12} sm={12} >
<div className=" p-5 bg-slate-300 rounded-md shadow-md">

                <Typography variant="body2" gutterBottom>
                  Additional Links:
                </Typography>
                <ul>
                  {book.additionalLinks.map((link, idx) => (
                    <li key={idx}>
                      <Link href={link.url} target="_blank" rel="noopener">
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
                  </div>

</Grid>
                </Grid>



                  </CardContent>


                </Card>
                 



          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ paddingTop: 0 }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default VerticalTabs;
