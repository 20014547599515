import React from 'react'

export default function StudyTechniquesPage() {
  return (
    <div>
      StudyTechniquesPage

    </div>
  )
}
