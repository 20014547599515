import React from "react";
import Navbar from "../StrongComponents/Navbar";
import { Grid, Box, Typography, Link, Container, Divider } from "@mui/material";
import NcertLogo from "../NCERTImagesFolder/NcertLogo.png";
import TabsComponent from '../StrongComponents/TabsComponent';

const CoursesPage = () => {
  

  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        <Box sx={{ textAlign: "center", my: 4 }}>
          <div className="flex justify-center p-2 items-center bg-pink-300 rounded-md">
            <a
              className="p-0 m-0"
              href="https://ncert.nic.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="w-40 h-auto" src={NcertLogo} alt="NCERT Logo" />
            </a>
          </div>

          <div className="text-2xl font-bold p-3 bg-pink-300 rounded-md">
            <h1>
              National Council of Educational Research and Training (NCERT)
            </h1>
            <h1 className="flex justify-center items-center p-3">
              राष्ट्रीय शैक्षिक अनुसंधान और प्रशिक्षण परिषद
            </h1>
          </div>

          <div className="border p-5">
            <p>
              The National Council of Educational Research and Training (NCERT)
              is an autonomous organization of the Government of India that was
              established in 1961. It is responsible for ensuring qualitative
              improvement in school education by preparing and publishing model
              textbooks, supplementary material, educational kits, multimedia
              digital materials, etc.
            </p>
          </div>
          
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">
              Why Choose NCERT Books for NEET?
            </h2>
            <ul className="list-disc pl-6">
              <li className="mb-2">
                Comprehensive coverage of NEET syllabus ensures all essential
                topics are covered.
              </li>
              <li className="mb-2">
                Clear and concise explanations enhance conceptual understanding.
              </li>
              <li className="mb-2">
                Direct correlation with NEET exam questions makes them
                indispensable for exam preparation.
              </li>
              <li className="mb-2">
                Recommended and trusted by educational authorities for
                standardized and reliable content.
              </li>
              <li className="mb-2">
                High-quality illustrations aid visual learning and improve
                comprehension, particularly in Biology.
              </li>
              <li className="mb-2">
                Affordable and accessible, ensuring equitable access to quality
                study material for all students.
              </li>
              <li className="mb-2">
                Widely accepted by teachers and institutions, ensuring
                consistency and recognition in academic circles.
              </li>
            </ul>
          </div>

          <div className="bg-gray-100 p-6 m-5 rounded-lg shadow-md">
            <p className="text-lg mb-2">
              The 10th Grade Science book is included for its foundational
              importance in preparing students for advanced topics crucial to
              NEET.
            </p>
            <p className="text-lg">
              It establishes fundamental concepts in Biology, Chemistry, and
              Physics, essential for competitive exam readiness.
            </p>
          </div>
        </Box>
<section>
  <TabsComponent/>
</section>
      
      </Container>
    </>
  );
};

export default CoursesPage;
