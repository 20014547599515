import React from 'react';
import Navbar from '../StrongComponents/Navbar';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PeriodicTable from '../StrongComponents/PeriodicTable';


const HomePage = () => {
  

  

  
 

  return (
    <div
     
    >
      <Navbar />
      <Helmet>
        <title>NEET Study Hub</title>
        <meta name="description" content="Join NEET Study Hub for comprehensive and free courses tailored to help students ace the NEET exam. Access top-quality study materials, expert guidance, and more." />
        <meta name="keywords" content="NEET preparation, free NEET courses, NEET study materials, NEET exam guidance, NEET Study Hub, NEET coaching, NEET online courses, NEET resources, study matarial" />
      </Helmet>

      {/* Welcome Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Welcome To NEET Study Hub</h1>
          <p className="text-lg md:text-xl mb-8">Prepare with Confidence. Learn with Ease.</p>
          <Link to="/get-started" className="bg-white text-blue-600 px-8 py-3 rounded-full font-bold text-lg hover:bg-blue-700 hover:text-white transition duration-300">
            Get Started
          </Link>
        </div>
      </section>

      {/* NEET Preparation Roadmap */}
      {/* Roadmap component should be here */}

      {/* Official NEET Syllabus */}
      {/* Official NEET Syllabus content should be here */}

      {/* NEET Study Hub Syllabus */}
      <section className="py-12 bg-gray-100">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">NEET Study Hub Syllabus</h2>
          <p className="text-lg mb-4">Explore our detailed syllabus crafted specifically for NEET aspirants:</p>
          <Link to="/practice-tests" className="bg-blue-600 text-white px-8 py-3 rounded-full font-bold text-lg hover:bg-blue-700 transition duration-300">
            Lets Start
          </Link>
        </div>
      </section>
      <section>

        <div>
          <PeriodicTable/>
        </div>
      </section>

      {/* AI Image */}
   

      {/* Data Sections */}
      {/* Insert the data sections provided in your previous message here */}
    </div>
  );
};

export default HomePage;
