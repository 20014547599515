import React from 'react';
import { Link } from 'react-router-dom';

const Roadmap = () => {
  // Define roadmap data
  const roadmapSteps = [
    {
      title: "Introduction to NEET Exam",
      description: "Overview of NEET exam, its importance, format, and eligibility criteria.",
      route: "/introduction-to-neet-exam"
    },
    {
      title: "Study Techniques and Strategies",
      description: "Effective study techniques, time management, and exam tackling strategies.",
      route: "/study-techniques"
    },
    {
      title: "Physics Fundamentals",
      description: "Basics of physics including mechanics, thermodynamics, electromagnetism, optics, and modern physics.",
      route: "/physics-fundamentals"
    },
    {
      title: "Chemistry Fundamentals",
      description: "Foundational principles of chemistry covering atomic structure, chemical bonding, states of matter, and more.",
      route: "/chemistry-fundamentals"
    },
    {
      title: "Biology Fundamentals",
      description: "Essentials of biology including cell biology, genetics, ecology, physiology, and human anatomy.",
      route: "/biology-fundamentals"
    },
    {
      title: "Practice Exercises and Problem Sets",
      description: "Plenty of practice exercises and problem sets for reinforcing learning.",
      route: "/practice-problem-sets"
    },
    {
      title: "Mock Tests and Sample Papers",
      description: "Mock tests and sample papers for simulating the exam environment and assessing progress.",
      route: "/mock-tests-sample-papers"
    },
    {
      title: "Revision and Doubt Clearing Sessions",
      description: "Regular revision sessions to consolidate learning and address doubts.",
      route: "/revision-doubt-clearing"
    },
    {
      title: "Test-Taking Strategies",
      description: "Tips and strategies for approaching exam questions and managing time effectively.",
      route: "/test-taking-strategies"
    },
    {
      title: "Feedback and Support",
      description: "Encourage feedback and provide support throughout the preparation journey.",
      route: "/feedback-support"
    },
  ];

  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">NEET Preparation Roadmap</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          {/* Roadmap Steps */}
          {roadmapSteps.map((step, index) => (
            <Link key={index} to={step.route} className="block">
              <div className="flex flex-col items-center border p-6 rounded-lg shadow-md bg-white transition duration-300 hover:shadow-lg hover:bg-blue-50 h-full">
                <div className="rounded-full bg-blue-600 h-14 w-14 flex items-center justify-center text-white font-bold text-xl mb-4">
                  {index + 1}
                </div>
                <div className="text-center">
                  <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
                  <p className="text-gray-700">{step.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
