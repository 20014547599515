import React, { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';

const BohrModel = ({ element }) => {
  const nucleusRef = useRef();
  const electronRefs = useRef([]);

  useEffect(() => {
    if (element && element.electrons) {
      electronRefs.current = electronRefs.current.slice(0, element.electrons.length);
      console.log("Element Data in BohrModel: ", element);
    }
  }, [element]);

  useFrame(() => {
    if (element && element.electrons) {
      element.electrons.forEach((electron, index) => {
        const electronMesh = electronRefs.current[index];
        if (electronMesh) {
          electronMesh.position.x = electron.orbitRadius * Math.cos(Date.now() * 0.001 * electron.speed);
          electronMesh.position.z = electron.orbitRadius * Math.sin(Date.now() * 0.001 * electron.speed);
        }
      });
    }
  });

  if (!element || !element.electrons) {
    return null;
  }

  return (
    <group>
      <mesh ref={nucleusRef}>
        <sphereGeometry args={[1, 32, 32]} />
        <meshBasicMaterial color="red" />
      </mesh>
      {element.electrons.map((electron, index) => (
        <mesh key={index} ref={el => (electronRefs.current[index] = el)}>
          <sphereGeometry args={[0.1, 32, 32]} />
          <meshBasicMaterial color="blue" />
        </mesh>
      ))}
    </group>
  );
};

export default BohrModel;
