import React from 'react'

export default function ChemistryFundamentalsPage() {
  return (
    <div>
      ChemistryFundamentalsPage

    </div>
  )
}
