import React from 'react';
import { Canvas } from '@react-three/fiber';
import BohrModel from '../StrongComponents/BohrModel';

const ElementCard = ({ element, onClose }) => {
  // Log the element data to ensure it is being passed correctly
  console.log("Element Data in ElementCard: ", element);

  return (
    <div className="fixed top-10 left-10 right-10 bg-white p-6 shadow-lg z-50">
      <button onClick={onClose} className="float-right bg-red-500 text-white px-2 py-1 rounded">Close</button>
      <h2 className="text-xl font-bold mb-2">{element.name}</h2>
      <p className="mb-2">{element.description}</p>
      <p className="mb-1"><strong>Symbol:</strong> {element.symbol}</p>
      <p className="mb-1"><strong>Atomic Number:</strong> {element.atomicNumber}</p>
      <p className="mb-1"><strong>Atomic Mass:</strong> {element.atomicMass}</p>
      <p className="mb-1"><strong>Melting Point:</strong> {element.meltingPoint}</p>
      <p className="mb-1"><strong>Boiling Point:</strong> {element.boilingPoint}</p>
      <p className="mb-1"><strong>Discovery Date:</strong> {element.discoveryDate}</p>
      <p className="mb-1"><strong>Discovered By:</strong> {element.discoveredBy}</p>
      <Canvas style={{ height: '300px' }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <BohrModel element={element} />
      </Canvas>
    </div>
  );
};

export default ElementCard;
