import React from 'react'
import Navbar from '../StrongComponents/Navbar'

export default function SyllabusPage() {
  return (
    <div>
        <Navbar/>
    <div>
    <section className="py-12">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">Official NEET Syllabus</h2>
          <p className="text-lg mb-4">Download the official NEET syllabus PDF to know the complete details:</p>
          <a href="https://www.nmc.org.in/MCIRest/open/getDocument?path=/Documents/Public/Portal/LatestNews/NEET%20UG%202024_Approved_Final.pdf" className="bg-blue-600 text-white px-6 sm:px-8 py-3 rounded-full font-bold text-lg hover:bg-blue-700 transition duration-300 block mx-auto max-w-md">
            Download Official NEET Syllabus PDF
          </a>
        </div>
      </section>
    </div>
      </div>
  )
}
